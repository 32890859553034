"use strict";
window.addEventListener("load", function () {
    var btnFacebook = document.getElementById("btn-facebook");
    var facebookLoginUrl = document.getElementById("viewbag-login").dataset.facebookLoginUrl;
    if (btnFacebook) {
        btnFacebook.addEventListener("click", function (e) {
            e.preventDefault();
            FB.login(function (response) {
                console.log(response);
                if (response.status === "connected") {
                    window.location.href = facebookLoginUrl + "?accessToken=" + response.authResponse.accessToken;
                }
                else if (response.status === "not_authorized") {
                    alert("Per favore autorizza l'applicazione");
                }
                else {
                    alert("Accesso a facebook annullato o non riuscito.");
                }
            }, { scope: "email" });
        });
    }
});
